<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <p>
              <el-button v-if="isadmin" type="primary" @click="add_store">{{
                $t("Add")
              }}</el-button>
            </p>
            <p class="p-3" v-if="storelist">
              <el-table
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="storelist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="ID" prop="id">
                  <template v-slot="{ row }">
                    {{ row.id }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name')" prop="name">
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="status">
                </el-table-column>
                <el-table-column :label="$t('Queue')" prop="lineup">
                </el-table-column>
                <el-table-column :label="$t('Reserve')" prop="reserve">
                </el-table-column>
                <el-table-column :label="$t('Date')" prop="create_tm">
                </el-table-column>
                <el-table-column label="">
                  <template v-slot="{ row }">
                    <b-button class="btn btn-success" size="sm" @click="go_setup_reserve(row)">{{ $t('Reserve Setup') }}</b-button>
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store Name')">
                      <b-input
                        placeholder="Store Name"
                        class="form-control"
                        v-model="cur_store.name"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Password')">
                      <b-input
                        placeholder="Password"
                        class="form-control"
                        v-model="cur_store.passcode"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Select Time Zone')">
                      <select class="form-control" v-model="cur_store.zone">
                        <option v-for="tm in timezonelist" :key="tm" :value="tm">{{ tm }}</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Contact')">
                      <b-input
                        placeholder="Contact"
                        class="form-control"
                        v-model="cur_store.contact"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Telephone')">
                      <b-input
                        placeholder="Telephone"
                        class="form-control"
                        v-model="cur_store.phone"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Address')">
                      <b-input
                        placeholder="Address"
                        class="form-control"
                        v-model="cur_store.address"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Post Code')">
                      <b-input
                        placeholder="Post Code"
                        class="form-control"
                        v-model="cur_store.postcode"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Web Site')">
                      <b-input
                        placeholder="Web Site"
                        class="form-control"
                        v-model="cur_store.apiurl"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Ad Text')">
                      <b-input
                        placeholder="Ad Text"
                        class="form-control"
                        v-model="cur_store.ad_txt"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col>
                    <b-form-checkbox v-model="cur_store.lineup_apply_sms" switch :unchecked-value="0" value="1">
                      {{ $t('Send SMS on Apply Line up') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="cur_store.lineup_call_sms" switch :unchecked-value="0" value="1">
                      {{ $t('Send SMS when the queue arrives') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="cur_store.reserve_apply_sms" switch :unchecked-value="0" value="1">
                      {{ $t('Send SMS on Reservation') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col>
                    <b-form-checkbox v-model="cur_store.status" switch :unchecked-value="0" value="1">
                      {{ $t('Status') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="cur_store.lineup" switch :unchecked-value="0" value="1">
                      {{ $t('Queue') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="cur_store.reserve" switch :unchecked-value="0" value="1">
                      {{ $t('Reserve') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_store.remark"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Logo')">
                        <b-form-file
                        v-model="image1"
                        :state="Boolean(image1)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.logo!=''" :src="imageBase + cur_store.logo" class="imagesize" />
                  </b-col>
                  <b-col>
                    <base-input :label="$t('AD Image')">
                        <b-form-file
                        v-model="ad_img1"
                        :state="Boolean(ad_img1)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.ad_img1!=''" :src="imageBase + cur_store.ad_img1" class="imagesize" />
                  </b-col>
                  <b-col>
                    <base-input :label="$t('AD Image')+'2'">
                        <b-form-file
                        v-model="ad_img2"
                        :state="Boolean(ad_img2)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.ad_img2!=''" :src="imageBase + cur_store.ad_img2" class="imagesize" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('AD Image')+'3'">
                        <b-form-file
                        v-model="ad_img3"
                        :state="Boolean(ad_img3)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.ad_img3!=''" :src="imageBase + cur_store.ad_img3" class="imagesize" />
                  </b-col>
                  <b-col>
                    <base-input :label="$t('AD Image')+'4'">
                        <b-form-file
                        v-model="ad_img4"
                        :state="Boolean(ad_img4)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.ad_img4!=''" :src="imageBase + cur_store.ad_img4" class="imagesize" />
                  </b-col>
                  <b-col>
                    <base-input :label="$t('AD Image')+'5'">
                        <b-form-file
                        v-model="ad_img5"
                        :state="Boolean(ad_img5)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </base-input>
                    <b-img-lazy v-if="cur_store.ad_img5!=''" :src="imageBase + cur_store.ad_img5" class="imagesize" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-card-group deck class="text-center">
                      <b-card v-if="cur_queueurl" :title="$t('Queue')" style="max-width:200px;">
                        <vue-qrcode :value="cur_queueurl" />
                        <div  class="text-left">
                          {{ cur_queueurl }}
                        </div>
                      </b-card>
                      <b-card v-if="cur_reserveurl" :title="$t('Reserve')" style="max-width:200px;">
                        <vue-qrcode :value="cur_reserveurl" />
                        <div  class="text-left">
                          {{ cur_reserveurl }}
                        </div>
                      </b-card>
                    </b-card-group>
                  </b-col>
                </b-row>

                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_cancel">{{ $t("Back") }}</el-button>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_submit">{{ $t("Submit") }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import InputAutocomplete from "../InputAutocomplete";
import VueQrcode from "vue-qrcode";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
    InputAutocomplete,
    VueQrcode
  },
  data() {
    return {
      currpage: "list",
      cur_store: null,
      cur_opt: null,
      cur_queueurl: null,
      cur_reserveurl: null,

      pickr_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      new_store: {
        id: "",
        name: "",
        passcode: "",
        status: 1,
        lineup: 1,
        reserve: 1,
        lineup_apply_sms: 0,
        lineup_call_sms: 0,
        reserve_apply_sms: 0,
        zone: -8,
        logo: "",
        address: "",
        postcode: "",
        contact: "",
        phone: "",
        apiurl: "",
        ad_txt: "",
        Remarks: "",
      },
      storelist: null,

      image1: null,
      ad_img1: null,
      ad_img2: null,
      ad_img3: null,
      ad_img4: null,
      ad_img5: null,

      isadmin: null,

      token: "",
      user_id: 0,

      timezonelist: [-12,-11,-10,-9,-8,-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12],
    };
  },
  computed: {
    imageBase: function () {
      return axios.defaults.baseURL + "/Api";
    }
  },
  methods: {
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      if (column.label == "") {
        return;
      }
      this.currpage = "edit";
      this.cur_store = JSON.parse(JSON.stringify(row));
      this.generate_url();
      this.cur_opt = "update";
    },
    go_setup_reserve(row) {
      this.$router.push({ path: 'reservesetup', query: { store_id: row.id } });
    },
    generate_url() {
      if (!this.cur_store.passcode) {
        this.cur_queueurl = null;
        this.cur_reserveurl = null;
        return;
      }
      var url = axios.defaults.baseURL;
      // if (this.cur_store.apiurl) {
      //   url = this.cur_store.apiurl;
      // }
      if (!url) {
        url = "https://lineup.posking.ca";
      }

      let lastc = url.substr(url.length-1);
      if (lastc == "/") {
        this.cur_queueurl = url + "#/lineup?s="+this.cur_store.id+"&k="+this.cur_store.apppass;
        this.cur_reserveurl = url + "#/hold?s="+this.cur_store.id+"&k="+this.cur_store.apppass;
      } else {
        this.cur_queueurl = url + "/#/lineup?s="+this.cur_store.id+"&k="+this.cur_store.apppass;
        this.cur_reserveurl = url + "/#/hold?s="+this.cur_store.id+"&k="+this.cur_store.apppass;
      }
    },
    add_store() {
      this.currpage = "edit";
      this.cur_store = JSON.parse(JSON.stringify(this.new_store));
      this.generate_url();
      this.cur_opt = "add";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_store) {
        if (this.cur_store[key]) {
          bodyFormData.append(key, this.cur_store[key]);
        } else if ((key=="lineup_apply_sms") || (key=="lineup_call_sms") || (key=="reserve_apply_sms") || (key=="status") || (key=="lineup") || (key=="reserve")) {
          bodyFormData.append(key, this.cur_store[key]);
        }
      }
      bodyFormData.append("image1", this.image1);
      bodyFormData.append("ad_img1", this.ad_img1);
      bodyFormData.append("ad_img2", this.ad_img2);
      bodyFormData.append("ad_img3", this.ad_img3);
      bodyFormData.append("ad_img4", this.ad_img4);
      bodyFormData.append("ad_img5", this.ad_img5);

      axios({
        method: "post",
        url: "/Api/Web/Store/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async get_store_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.isadmin = rt.data.isadmin;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/weblogin");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/weblogin");
        return;
      }
      return this.get_store_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.card-title {
  margin-bottom: 0;
}
.imagesize {
  max-width: 100%;
}
</style>
